<template>
  <div class="tab">
    <el-footer class="tab__footer page__active_bar">
      <el-button id="editModelConfig" class="page__active_button" type="primary" @click="submit">
        Сохранить конфигурацию
      </el-button>
    </el-footer>
    <main class="page__container">
      <json-editor :json="form.config_template" :options="options" @change="setConfig" :completer="completer"/>
      <div v-for="(replacerName, index) in form.parsed_replacers"
:key="index">
        <range-editor
          v-if="CheckEditorType(replacerName, 'range')"
          :title="getTitleByReplacerName(replacerName)"
          :replacer-name="replacerName"
          :values="getRangeValuesByReplacerName(replacerName)"
          @updateValues="onUpdateRangeValues"
          @removeEditor="onRemoveEditor"
        />
        <switch-editor
          v-if="CheckEditorType(replacerName, 'switch')"
          :title="getTitleByReplacerName(replacerName)"
          :replacer-name="replacerName"
          :values="getSwitchValuesByReplacerName(replacerName)"
          @updateValues="onUpdateSwitchValue"
          @removeEditor="onRemoveEditor"
        />
        <enum-editor
          v-if="CheckEditorType(replacerName, 'enum')"
          :title="getTitleByReplacerName(replacerName)"
          :replacer-name="replacerName"
          :selected-values="getEnumSelectedValuesByReplacerName(replacerName)"
          :default-value="getEnumDefaultValueByReplacerName(replacerName)"
          :all-items="getEnumAllValuesByReplacerName(replacerName)"
          :is-client-editable="getEnumIsClientEditableByReplacerName(replacerName)"
          @updateValues="onUpdateEnumValues"
          @removeEditor="onRemoveEditor"
        />
      </div>
    </main>
  </div>
</template>

<script>
import JsonEditor from "./JsonEditor";
import { mapActions } from "vuex";
import RangeEditor from "./RangeEditor";
import SwitchEditor from "./SwitchEditor";
import EnumEditor from "./EnumEditor";

export default {
  name: "ModelFormEditConfigurations",
  components: { SwitchEditor, JsonEditor, RangeEditor, EnumEditor },

  data() {
    return {
      fetch: false,
      loading: true,
      options: { 
        modes: [ "code", "tree", "text", "form", "preview"],
        autocomplete: {
          filter: "contain",
          getOptions: () => {return ["test"]}
        }
      },
      completer: {
        identifierRegexps: [/[a-zA-Z_0-9#]/],
        getCompletions(editor, session, pos, prefix, callback) {
          // TODO: Будет работать после обновления пакета jsoneditor, ждет проверки и добавления в whiltelist. DEVOPS-5538
          // TODO: Использовать то, что приходит с бэкенда
          var wordList = [
            {replacer_name: "#resolution_main"},
            {replacer_name: "#keygop_main"},
            {replacer_name: "#framerate_main"},
            {replacer_name: "#bitrate_main"},
            {replacer_name: "#profile_main"},
            {replacer_name: "#rc_main"},
            {replacer_name: "#quality_main"},
            {replacer_name: "#audioen_main"},
            {replacer_name: "#resolution_sub"},
            {replacer_name: "#keygop_sub"},
            {replacer_name: "#framerate_sub"},
            {replacer_name: "#bitrate_sub"},
            {replacer_name: "#profile_sub"},
            {replacer_name: "#rc_sub"},
            {replacer_name: "#quality_sub"},
            {replacer_name: "#audioen_sub"},
            {replacer_name: "#image_rotation"},
            {replacer_name: "#microphone_level"},
            {replacer_name: "#sound"}
          ];
          callback(null, wordList.map(function(word) {
              return {
                  caption: word['replacer_name'],
                  value: word['replacer_name'],
                  meta: "static"
              };
          }));
        }
      },
      form: {
        config_template: {},
        parsed_replacers: {},
        available_replacers: {},
        available_config_templates: {},
        model_parameters: {},
        model_parameters_for_send: [],
      },
    };
  },
  created() {
    this.id = parseInt(this.$route.params.id);

    this.getModelConfigTemplate(this.id)
      .then((response) => {
        this.form.config_template = {
          ...response.data,
        };
      })
      .then(() => this.parseReplacers())
      .then(() =>
        this.getModelParameters(this.id).then((response) => {
          this.form.model_parameters = {
            ...response.data,
          };
        })
      )
      .then(() => this.getAvailableReplacers())
      .then((this.loading = false))
      .catch(({ response }) => {
        this.$message({
          message: response.data.error,
          type: "error",
        });
        this.loading = false;

      });
  },
  methods: {
    onUpdateEnumValues(data) {
      let id_camera_config = this.getIdCameraConfigParameter(data.replacer);
      let index = this.getIndexOfModelParameter(id_camera_config);
      this.form.model_parameters_for_send[index] = {
        id_camera_config_parameter: id_camera_config,
        default_value: data.default_value.value,
        default_text: data.default_value.text,
        available_values: data.values,
        is_client_editable: data.is_client_editable,
      };
    },
    onUpdateRangeValues(data) {
      let id_camera_config = this.getIdCameraConfigParameter(data.replacer);
      let index = this.getIndexOfModelParameter(id_camera_config);
      this.form.model_parameters_for_send[index] = {
        id_camera_config_parameter: id_camera_config,
        default_value: data.defaultValue,
        available_values: data.available_values,
        is_client_editable: data.is_client_editable,
      };
    },
    onUpdateSwitchValue(data) {
      let id_camera_config = this.getIdCameraConfigParameter(data.replacer);
      let index = this.getIndexOfModelParameter(id_camera_config);
      this.form.model_parameters_for_send[index] = {
        id_camera_config_parameter: id_camera_config,
        default_value: data.defaultValue,
        available_values: data.available_values,
        is_client_editable: data.is_client_editable,
      };
    },
    onRemoveEditor(data) {
      let id_camera_config = this.getIdCameraConfigParameter(data.replacer);
      let index = this.getIndexOfModelParameter(id_camera_config);
      this.form.model_parameters_for_send.splice(index, 1);
    },
    getIdCameraConfigParameter(replacerName) {
      let id_config_parameter = -1;
      for (let i in this.form.available_config_templates) {
        if (this.form.available_config_templates[i].replacer_name === replacerName) {
          id_config_parameter = this.form.available_config_templates[i].id_camera_config_parameter;
          break;
        }
      }
      return id_config_parameter;
    },
    getIndexOfModelParameter(id_camera_config) {
      let index = -1;
      for (let i in this.form.model_parameters_for_send) {
        if (this.form.model_parameters_for_send[i].id_camera_config_parameter === id_camera_config) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        index = this.form.model_parameters_for_send.length;
      }
      return index;
    },
    CheckEditorType(replacerName, expectedType) {
      let isExpectedType = false;
      for (let i in this.form.available_config_templates) {
        if (
          this.form.available_config_templates[i].type === expectedType &&
          this.form.available_config_templates[i].replacer_name === replacerName
        ) {
          isExpectedType = true;
          break;
        }
      }
      return isExpectedType;
    },
    getTitleByReplacerName(replacerName) {
      let title = null;
      for (let i in this.form.available_config_templates) {
        if (this.form.available_config_templates[i].replacer_name === replacerName) {
          title = this.form.available_config_templates[i].description;
          break;
        }
      }
      return title;
    },
    getEnumSelectedValuesByReplacerName(replacerName) {
      for (let i in this.form.model_parameters) {
        if (this.form.model_parameters[i].replacer_name === replacerName) {
          return this.form.model_parameters[i].available_values;
        }
      }
      return null;
    },
    getEnumAllValuesByReplacerName(replacerName) {
      for (let i in this.form.available_config_templates) {
        if (this.form.available_config_templates[i].replacer_name === replacerName) {
          return this.form.available_config_templates[i].values;
        }
      }
      return null;
    },
    getEnumDefaultValueByReplacerName(replacerName) {
      for (let i in this.form.model_parameters) {
        if (this.form.model_parameters[i].replacer_name === replacerName) {
          return {
            id: 0,
            text: this.form.model_parameters[i].default_text,
            value: this.form.model_parameters[i].default_value,
          };
        }
      }
    },
    getEnumIsClientEditableByReplacerName(replacerName) {
      for (let i in this.form.model_parameters) {
        if (this.form.model_parameters[i].replacer_name === replacerName) {
          return this.form.model_parameters[i].is_client_editable
        }
      }
    },
    getRangeValuesByReplacerName(replacerName) {
      let result = {
            minValue: null,
            maxValue: null,
            step: null,
            defaultValue: null,
            isClientEditable: 1
      }
      for (let i in this.form.model_parameters) {
        if (this.form.model_parameters[i].replacer_name === replacerName) {
           result = {
            minValue: this.form.model_parameters[i].available_values.min_value.toString(),
            maxValue: this.form.model_parameters[i].available_values.max_value.toString(),
            step: this.form.model_parameters[i].available_values.step.toString(),
            defaultValue: this.form.model_parameters[i].default_value,
            isClientEditable: this.form.model_parameters[i].is_client_editable
          };
        }
      }
      return result
    },
    getSwitchValuesByReplacerName(replacerName) {
      let result = {
        activeValue: null,
        disabledValue: null,
        defaultValue: null,
        isClientEditable: 1
      }
      for (let i in this.form.model_parameters) {
        if (this.form.model_parameters[i].replacer_name === replacerName) {
          result = {
            activeValue: this.form.model_parameters[i].available_values.active_value,
            disabledValue: this.form.model_parameters[i].available_values.disabled_value,
            defaultValue: this.form.model_parameters[i].default_value,
            isClientEditable: this.form.model_parameters[i].is_client_editable
          };
        }
      }
      return result
    },
    parseReplacers() {
      const regexLiteral = /#\w+/gm;
      this.form.parsed_replacers = {
        ...JSON.stringify(this.form.config_template).match(regexLiteral),
      };
    },
    getAvailableReplacers() {
      this.getConfigParameters()
        .then(
          (response) =>
            (this.form.available_config_templates = {
              ...response.data,
            })
        )
        .then(() => {
          const parameters = JSON.parse(JSON.stringify(this.form.available_config_templates));
          for (let i in parameters) {
            this.form.available_replacers[i] = parameters[i].replacer_name;
          }
        })
        .catch(({ response }) => {
          this.$message({
            message: response.data.error,
            type: "error",
          });
        });
    },
    setConfig(config = {}) {
      this.form.config_template = config;
      this.parseReplacers();
    },
    validateReplacers() {
      let valid = false;
      for (let i in this.form.parsed_replacers) {
        valid = false;
        for (let k in this.form.available_replacers) {
          if (this.form.available_replacers[k] === this.form.parsed_replacers[i]){
            valid = true;
          }
        }
        if (!valid) {
          this.$message({
            message: `Неизвестный реплейсер ${this.form.parsed_replacers[i]}`,
            type: "error",
          });
          break;
        }
      }
      return valid;
    },
    submit(event) {
      event.preventDefault();

      if (this.validateReplacers()) { 
        this.setModelParameters({
          id: this.id,
          data: this.form.model_parameters_for_send,
        })
          .then(() =>
            this.setModelConfigTemplate({
              id: this.id,
              data: this.form.config_template,
            })
          )
          .then(() => {
            this.$message({
              message: "Конфигурация успешно обновлена",
              type: "success",
            });
          })
          .catch(({ response }) => {
            this.$message({
              message: response.data.error,
              type: "error",
            });
          });
      }
    },
    ...mapActions("models", {
      getModelConfigTemplate: "GET_MODEL_CONFIG_TEMPLATE",
      setModelConfigTemplate: "SET_MODEL_CONFIG_TEMPLATE",
      getConfigParameters: "GET_CAMERAS_CONFIG_PARAMETERS",
      getModelParameters: "GET_MODEL_PARAMETERS",
      setModelParameters: "SET_MODEL_PARAMETERS",
    }),
  },
};
</script>

<style lang="postcss">
.jsoneditor {
  height: 530px;
}

div.jsoneditor-outer {
  height: calc(100% - 25px);
}

.tab__footer {
  width: calc(100% - 300px);
  margin-left: -20px;
  bottom: 0;
  height: 60px;
  position: fixed;
}

.tab {
  display: flex;
  flex-direction: column;
}

.page_container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tab .el-footer {
  width: calc(100% - 249px);
  margin-left: -20px;
  bottom: 0;
  height: 60px;
  position: fixed;
}
.tab .page__container {
  height: calc(100% - 60px);
  padding: 0;
}

.tariffs .el-tabs__header {
  display: none;
}

.tariffs .el-tabs__content {
  height: 100% !important;
}
</style>
