<template>
  <div ref="jsoneditor" />
</template>

<script>
import JSONEditor from "jsoneditor/dist/jsoneditor.js";
import "jsoneditor/dist/jsoneditor.min.css";
import _ from "lodash";
export default {
  name: "JsonEditor",
  props: {
    json: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    completer: {
      type: Object
    }
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    json: {
      handler(newJson) {
        if (this.editor) {
          this.editor.update(newJson);
        }
      },
      deep: true,
    },
  },
  mounted() {
    const container = this.$refs.jsoneditor;
    const options = _.extend(
      {
        onChange: this._onChange,
      },
      this.options
    );
    this.editor = new JSONEditor(container, options);
    
    this.editor.set(this.json);
    this.editor.aceEditor.completers = [this.completer]
    this.editor.aceEditor.setOptions({
      enableSnippets: true,
      enableLiveAutocompletion: true
    })
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },
  methods: {
    _onChange() {
      if (this.editor) {
        this.$emit("change", this.editor.get());
      }
    },
  },
};
</script>
